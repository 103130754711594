<template>
  <PageModalWrapper v-model="modal" @input="close">
    <template #content>
      <div class="order-card">
        <div class="order-card__header">
          <div>
            <p>{{$t('order')}} <span class="color-primary">{{ shipmentNumber }}</span></p>
            <p class="small-text text-12-400">
              {{ orderCreationDate }}, {{ orderCreationTime }}
            </p>
          </div>

          <LinkWithIcon
            v-if="showShipmentLabel"
            @click="getShippingLabel({ orderId: order.id, shipmentNumber})"
            icon-name="pdf"
            :href="`/order/${this.$route.params.orderId}/label`"
            target="_blank">
            {{ $t('shippingLabel') }}
          </LinkWithIcon>
        </div>
        <div class="order-card__body">
          <h3 class="text-20-800">{{ $t('orderDetails') }}</h3>
          <table class="order-details">
            <tbody>
            <tr>
              <td>
                {{ $t('ordersTableStatus') }}
              </td>
              <td v-text="shipmentNumber"></td>
            </tr>
            <tr>
              <td>
                {{ $t('orderStatus') }}
              </td>
              <td v-text="status"></td>
            </tr>
            <tr>
              <td>
                {{ $t('trackNumber') }}
              </td>
              <td v-text="trackNumber"></td>
            </tr>
            <tr>
              <td>
                {{ $t('orderCreationDate') }}
              </td>
              <td v-text="orderCreationDate"></td>
            </tr>
            <tr v-if="orderShippedToSMDate">
              <td>
                {{ $t('orderShippedToSMDate') }}
              </td>
              <td v-text="orderShippedToSMDate"></td>
            </tr>
            <tr v-if="orderShippedToCustomerDate">
              <td>
                {{ $t('orderShippedToCustomerDate') }}
              </td>
              <td v-text="orderShippedToCustomerDate"></td>
            </tr>
            <tr v-if="orderDeliveryDate">
              <td>
                {{ $t('orderDeliveryDate') }}
              </td>
              <td v-text="orderDeliveryDate"></td>
            </tr>
            <tr v-if="order.marketplace">
              <td>
                {{ $t('paymentTable.marketplace') }}
              </td>
              <td>
                <BrandLogo
                  style="display: block; height: 22px;"
                  :name="order.marketplace.name.toLowerCase()"
                />
              </td>
            </tr>
            </tbody>
          </table>
          <h4 class="text-20-800">{{ $t('orderList') }}</h4>
          <BaseTable>
            <template #header>
              <th>
                Photo
              </th>
              <th>
                Product name
              </th>
              <th>
                {{ $t('listSKU') }}
              </th>
              <th>
                {{ $t('modelQuantity') }}
              </th>
              <th>
                <p>{{ $t('cost') }}</p>
                <p>{{ $t('perItem') }}</p>
              </th>
              <th>
                <p>{{ $t('cost') }}</p>
                <p>{{ $t('perProduct') }}</p>
              </th>
            </template>
            <template #body>
              <tr v-for="item in orderItems" :key="item.sku">
                <td>
                  <ProductImage
                    :url="order.orderItems[0]?.photo"
                  />
                </td>
                <td>
                  {{ item.name }}
                  <p class="small-text">
                    {{ order.orderItems[0]?.category?.name }}, {{ order.orderItems[0]?.brand?.name }}
                  </p>
                </td>
                <td v-text="item.sku"></td>
                <td v-text="item.quantity"></td>
                <td v-text="`$${item.price}`"></td>
                <td v-text="`$${item.total}`"></td>
              </tr>
            </template>
          </BaseTable>

          <div class="order-total text-24-800">
            <span class="order-total-key">
              {{ $t('total') }}
            </span>
            <span class="order-total-value">
              ${{ order.totalSum }}
            </span>
          </div>
        </div>
        <div v-if="showActionButtons" class="order-card__footer button-wrap">
          <WarnButton
            @click="rejectOrder"
            :value="$t('reject')"
            :disabled="acceptRejectDisabled"
          />
          <simple-button
            @click="sentOrderToSM"
            :value="$t('sentToSm')"
            :disabled="sentToSMButtonDisabled"
          />
          <simple-button
            @click="acceptOrder"
            :value="$t('approve')"
            :disabled="acceptRejectDisabled"
          />
        </div>
      </div>
    </template>
  </PageModalWrapper>
</template>

<script>
import LinkWithIcon from '../../ui/ links/LinkWithIcon'
import WarnButton from '../../ui/buttons/WarnButton'
import BaseTable from '../../ui/tables/BaseTable'
import PageModalWrapper from '../../ui/modals/PageModalWrapper'
import { mapState } from 'vuex'
import ordersStatuses, {
  ORDER_STATUS_PENDING_PROCESSING,
  ORDER_STATUS_ACCEPTED_BY_SELLER,
  ORDER_STATUS_SENT_TO_SM,
  ORDER_STATUS_CANCELLED_BY_SELLER
} from '../../../helpers/consts/order-statuses'
import helpers from '../../../helpers/helpers'
import BrandLogo from '../billing/BrandLogo'
import ProductImage from "@/components/ui/images/ProductImage.vue";

export default {
  name: 'Order',
  data () {
    return {
      modal: false,
    }
  },
  components: {
    ProductImage,
    PageModalWrapper,
    BrandLogo,
    BaseTable,
    WarnButton,
    LinkWithIcon,
  },

  computed: {
    ...mapState({
      order: state => state.orders.order,
    }),
    shipmentNumber () {
      return this.order.orderId
    },
    status () {
      return ordersStatuses[this.order.status]
    },
    trackNumber () {
      return this.order.trackNumber
    },
    orderCreationDate () {
      return helpers.getDate(new Date(this.order.createdAt))
    },
    orderCreationTime () {
      return helpers.getTime(new Date(this.order.createdAt))
    },
    orderShippedToSMDate () {
      return this.order.shippedToSmWarehouseAt ? helpers.getDate(new Date(this.order.shippedToSmWarehouseAt)) : ''
    },
    orderShippedToCustomerDate () {
      return this.order.shippedToCustomerAt ? helpers.getDate(new Date(this.order.shippedToCustomerAt)) : ''
    },
    orderDeliveryDate () {
      return this.order.deliveryToClientAt ? helpers.getDate(new Date(this.order.deliveryToClientAt)) : ''
    },
    orderItems () {
      return this.order.orderItems
    },

    showActionButtons () {
      switch (this.order.status) {
        case ORDER_STATUS_PENDING_PROCESSING:
        case ORDER_STATUS_ACCEPTED_BY_SELLER:
        case ORDER_STATUS_SENT_TO_SM:
          return true
        default:
          return false
      }
    },

    showShipmentLabel () {
      switch (this.order.status) {
        case ORDER_STATUS_PENDING_PROCESSING:
        case ORDER_STATUS_CANCELLED_BY_SELLER:
          return false
        default:
          return true
      }
    },

    acceptRejectDisabled () {
      return this.order.status !== ORDER_STATUS_PENDING_PROCESSING
    },

    sentToSMButtonDisabled () {
      return this.order.status !== ORDER_STATUS_ACCEPTED_BY_SELLER
    },
  },

  created () {
    this.getOrder()
  },

  methods: {
    close () {
      this.$router.push({
        name: 'orders',
        params: {
          status: this.$route.params.status,
        },
      })
    },

    getShippingLabel ({ orderId, shipmentNumber }) {
      this.$store.dispatch('loadShippingLabel', {
        orderId,
        shipmentNumber,
      })
    },

    rejectOrder () {
      this.changeOrderStatus(ORDER_STATUS_CANCELLED_BY_SELLER, this.$t('orderRejected'))
    },

    acceptOrder () {
      this.changeOrderStatus(ORDER_STATUS_ACCEPTED_BY_SELLER, this.$t('orderAccepted'))
    },

    sentOrderToSM () {
      this.changeOrderStatus(ORDER_STATUS_SENT_TO_SM, this.$t('orderSendToSM'))
    },

    async changeOrderStatus (status, message) {
      try {
        this.$store.commit('setLoadingStatus')
        await this.$store.dispatch('changeOrderStatus', {
          orderId: this.order.id,
          status,
        })
        await this.$store.commit('removeOrderFromOrders', this.order?.id)
        await this.$store.commit('decrementStatusCount', this.order?.status)
        await this.getOrder()
        this.$store.commit('showHintNotification', {
          type: 'SUCCESS',
          title: `${this.$t('order')}: ${this.order?.orderId}`,
          description: message,
        })
      } catch (error) {
        await this.$store.dispatch('showError', error)
      } finally {
        this.$store.commit('setSuccessStatus')
      }
    },

    async getOrder () {
      this.$store.commit('setLoadingStatus')

      const orderId = this.$route.params.orderId
      await this.$store.dispatch('getOrder', { orderId })

      this.modal = true
      this.$store.commit('setSuccessStatus')
    },
  },
}
</script>

<style lang="scss" scoped>

.order-card {
  height: 100%;
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &__header {
    font-size: 16px;
    font-weight: 400;
    padding: 0 0 20px 0;
    border-bottom: 1px solid $color-line;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0 1 auto;
  }

  &__body {
    padding: 30px 20px;
    flex: 1 0 auto;

    h3 {
      margin-bottom: 20px;
    }

    h4 {
      margin-bottom: 20px;
    }
  }

  &__footer {
    padding: 20px;
    border-top: 1px solid $color-line;
    flex: 0 1 auto;

    button:not(:last-child) {
      margin-right: 20px;
    }
  }

  .order-details {
    margin-bottom: 50px;

    td {
      width: 200px;
      box-sizing: border-box;
      padding: 0 20px 20px 0;
      line-height: 16px;
      font-size: 14px;
      vertical-align: top;

      &:first-child {
        color: $text-gray;
      }
    }
  }

  .order-total {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    margin: 30px 0 0 auto;
  }
}

.product-name {
  color: $text-gray;
}

.color-primary {
  color: $color-primary;
}
.small-text {
  color: $text-gray;
}
td {
  vertical-align: middle;
}
</style>
